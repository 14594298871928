// author:马云阳；description:众惠-我的资产-公募基金
<template>
    <section class="publicfund">
        <div class="publicfund_cont">
          <div class="title_">
            高端理财
          </div>
          <div class="price_banner">
            <span class="ti_price">总金额</span>
            <div class="price_">
              <div class="price_num">
                {{fundData.fundShare||'--'}}
                <span class="yuan">元</span>
              </div>
            </div>
          </div>
          <div class="tag_cont">
            <div class="tag_">
            <div class="tag_img">
              <span class="iconfont" style="font-size:32px">&#xe690;</span>
            </div>
              <div class="tag_ti">
                持有收益<span>(元)</span>
              </div>
              <div class="tag_num">
                {{fundData.holdingIncome||'--'}}
              </div>
            </div>
            <div class="tag_">
            <div class="tag_img">
              <span class="iconfont" style="font-size:32px">&#xe68c;</span>
            </div>
              <div class="tag_ti">
                累计收益<span>(元)</span>
              </div>
              <div class="tag_num ">
                {{fundData.accumIncome||'--'}}
              </div>
            </div>
            <!-- <div class="tag_">
            <div class="tag_img">
              <span class="iconfont" style="font-size:32px">&#xe68f;</span>
            </div>
              <div class="tag_ti">
                累计收益率
              </div>
              <div :class="totalRate<0?'profit':'profitac'" class="tag_num ">
               <span v-if="totalRate>0&&totalRate!=0" style="font-size:16px;margin-right:2px;">+</span>{{totalRate||'--'}}<span v-if="totalRate!=0" style="font-size:16px;margin-left:2px;">%</span>
              </div>
            </div> -->
          </div>
          <div class="tables_cont">
            <TablieList :titles="'持仓明细'" :tableList="lists" @sellout="sellout" :trs="trs" :showrightBtn="false"  :operation="3" :showTips="false" />
          </div>
          <!-- <div class="foot_page_">
            <Pagebar :total="pageTotal" :display="5" @click="goPage" />
          </div> -->
        </div>
    </section>
</template>
<script>
import TablieList from "@/components/TablieList";
// import Pagebar from "@/components/Pagebar";
import { findTotalBond } from "@/api/publicfund";

export default {
  name: "PrivateFund",
  components: { TablieList },
  data() {
    return {
      trs: {
        title1: "基金",
        title2: "金额",
        title3: "持有收益",
        // title4: "累计收益率",
        title5: "操作"
      },
      userInfo: {}, //用户信息
      totalRate: 0, //累计收益率
      fundData: {
        holdingIncome:'--',
        fundShare:'--',
        accumIncome:'--'
      },
      lists:[],//列表数据
    };
  },
  methods: {
    getpotions() {
      let params = {
        clientId: this.userInfo.userId,
        bondMark: 1
      };
      findTotalBond(params).then(res => {
        if (res.code != 200) {
          this.$message.error("当前请求失败，请稍后再试！");
        } else {
          this.fundData = res.data;
          this.lists=res.data.list.map(item=>{
            item.totalRate=Math.floor(res.data.totalRate * 100);
            return item
          })
          if (res.datatotalRate != 0) {
            this.totalRate = Math.floor(res.data.totalRate * 100);
          } else {
            this.totalRate = res.data.totalRate;
          }
        }
      });
    },
    sellout(obj){
      this.$router.push({name:"SellOutFund",params:{fundCode:obj.fundCode}})
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("customerInfo")) || {};
    let arr = Object.keys(this.userInfo);
      if (arr.length > 0) {
      this.getpotions();
    }else{
      this.$router.push({name:'Login'});
      this.$message.error('暂未登陆！请先登录！');
      
    }
  }
};
</script>
<style lang="less" scoped>
.profitac {
  color: #e45247 !important;
}
.profit {
  color: #01b834 !important;
}
.publicfund {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  background-color: #f1f1f1;
  display: flex;
  .publicfund_cont {
    padding: 40px 40px 0px 40px;
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      height: 60px;
      border-bottom: 1px solid #edf1f7;
      font-size: 20px;
      color: #1f1f1f;
    }
    .price_banner {
      margin-top: 40px;
      height: 102px;
      padding: 16px 19px 0px 19px;
      border-radius: 7px;
      background-image: url("../../../static/img/my/my_fund_s_bg.png");
      background-repeat: no-repeat;
      background-size: 100%;
      .ti_price {
        color: #deb87b;
        font-size: 20px;
        font-weight: bold;
      }
      .price_ {
        display: flex;
        align-items: center;
        margin-top: 36px;

        img {
          width: 26px;
          height: 30px;
          margin-right: 6px;
        }
        .price_num {
          font-size: 30px;
          font-family: Bahnschrift, Bahnschrift-Light;
          font-weight: 300;
          color: #deb87b;
          .yuan {
            color: #deb87b;
            font-size: 14px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: bold;
          }
        }
      }
    }
    .tag_cont {
      margin-top: 73px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .tag_ {
        width: 267px;
        height: 200px;
        position: relative;
        border-radius: 32px;
        box-shadow: 0px 4px 12px 0px #edf1f7;
        .tag_img {
          position: absolute;
          width: 64px;
          height: 58px;
          left: 102px;
          top: -29px;
          background: linear-gradient(131deg, #4e494d 0%, #0b0409 100%);
          border-radius: 14px;
          box-shadow: 0px 3px 5px -1px #fff5ea;
          color: #deb87b;
          line-height: 58px;
          text-align: center;
        }
        .tag_ti {
          font-size: 20px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: center;
          color: #6b6b6b;
          padding-top: 69px;
          span {
            font-size: 12px;
          }
        }
        .tag_num {
          margin-top: 34px;
          font-size: 30px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #1f1f1f;
        }
      }
    }
    .tables_cont {
      margin-top: 42px;
    }
    .foot_page_ {
      margin-top: 26px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>


